import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import dateFormat from 'dateformat';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import SEO from '../components/SEO';
import Header from '../components/Header';
import ContentRow from '../components/ContentRow';
import Pagination from '../components/Pagination';
import RichText from '../components/RichText';
import { commonHeaderColor } from '../util';


function BlogListItem({ node, t }) {
  const { title, summary, slug, featuredImage, createdAt } = node;

  const hasImg = featuredImage && featuredImage.fluid;
  const blogUrl = `/blog/${slug}/`;

  return (
    <article className="blogListItem">
      <div className="grid c-2 alignItemsTop">
        <div>
          {
            hasImg &&
            <Link to={blogUrl}><Img fluid={featuredImage.fluid} alt={featuredImage.title} /></Link>
          }
        </div>
        <div>
          <h2><Link to={blogUrl}>{title}</Link></h2>
          <p className="time">{dateFormat(createdAt, t('dateTimeFormat'))}</p>
          <div className="markdown">
            <RichText json={summary.json} />
          </div>
        </div>
      </div>
    </article>
  );
}

const BlogList = ({ data, pageContext }) => {
  const { t } = useTranslation();

  const { page, totalCount, itemsPerPage } = pageContext;
  const maxPage = Math.ceil(totalCount / itemsPerPage);

  if (page > maxPage) {
    return null;
  }

  const pageSuffix = page > 1 ? ` - ${t('page')} ${page}` : '';

  return (
    <>
      <SEO
        title={`${t('blogListMetaTitle')}${pageSuffix}`}
        description={t('blogListMetaDescription')}
        alternateLanguagePages={[
          {
            path: `blog${page > 1 ? `/${page}` : ''}`,
            language: 'en'
          },
          {
            path: `blog${page > 1 ? `/${page}` : ''}`,
            language: 'fr'
          }
        ]}
      />
      <Header backgroundColor={commonHeaderColor} />
      <ContentRow backgroundColor={commonHeaderColor} padV>
        <div className="grid c-2">
          <div>
            <Img fluid={data.img1.childImageSharp.fluid} alt={t('blogListHeroAlt')} />
          </div>
          <div>
            <div className="contentBox textAlignCenter">
              <h1>{t('blogListTitle')}</h1>
            </div>
          </div>
        </div>
      </ContentRow>
      <div className="pageWidth">
        {data.allContentfulBlog.edges.map(({ node }) => {
          return <BlogListItem key={node.slug} node={node} t={t} />;
        })}
        <Pagination
          currentPage={page}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
          urlCallback={p => p === 1 ? '/blog/' : `/blog/${p}/`}
        />
      </div>
    </>
  );
};

export default BlogList;

export const pageQuery = graphql`
  query ($skip: Int!, $itemsPerPage: Int!, $language: String!) {
    img1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "blog/getneosurf-blog-news-hero.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulBlog(skip: $skip, limit: $itemsPerPage, sort: {fields: createdAt, order: DESC}, filter: {language: {code: {eq: $language}}}) {
      edges {
        node {
          createdAt
          slug
          title
          summary {
            json
          }
          featuredImage {
            title
            fluid(
              maxWidth: 912
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
