import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';


function Pagination({ currentPage, totalCount, itemsPerPage, urlCallback }) {
  const numOfPages = Math.ceil(totalCount / itemsPerPage);
  let p = 1;
  const pageList = [];

  if (numOfPages < 2) {
    return null;
  }

  while (p <= numOfPages) {
    pageList.push(
      <li
        key={p}
      >
        <Link
          className={p === currentPage ? 'button active' : 'button'}
          to={urlCallback(p)}
        >
          {p}
        </Link>
      </li>
    );

    p++;
  }

  return (
    <ol className="pagination">
      {pageList}
    </ol>
  );
}

export default Pagination;